<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? StockOrder__title : ''" help-page-component="AddStockOrder"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <StockOrderAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newStockOrderUnsaved"
                />
                <StockOrderAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedStockOrderUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="stockOrderForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels accordion multiple v-model="panel">
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-5 v-if="$can('create','StockOrderManual')">
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :label="$t('message.manualEntryReturn')"
                                                :true-value="1"
                                                class="ml-1 black--text text--darken-1"
                                                dense
                                                hide-details="auto"
                                                v-model="StockOrder__manual_entry"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <template v-if="StockOrder__manual_entry == 1">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.description') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="StockOrder__title ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                    :value="StockOrder__title"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    dense
                                                    hide-details
                                                    solo
                                                    @change="StockOrder__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                    :items=allSuppliers
                                                    :label="StockOrder__supplier_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    required
                                                    solo
                                                    v-model="StockOrder__supplier_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.company') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                    :label="StockOrder__office_id ? '' : $t('message.required')"
                                                    :items="whOffices"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Office.title"
                                                    item-value="Office.id"
                                                    solo
                                                    required
                                                    v-model="StockOrder__office_id"
                                                    @change="officeChanged($event)"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.arrivalDate') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="stockOrderStockInDateMenu"
                                                    transition="scale-transition"
                                                    v-model="stockOrderStockInDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :label="StockOrder__stockindate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedStockOrderStockInDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="StockOrder__stockindate"
                                                        @input="stockOrderStockInDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__product_type"
                                                >
                                                    <v-radio :label="$t('message.others')" :value="1"/>
                                                    <v-radio :label="$t('message.slicedVeneer')" :value="2"/>
                                                </v-radio-group>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__ms_id"
                                                    v-if="StockOrder__product_type === 1"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                    <v-radio :label="$t('message.imperial')" :value="2"/>
                                                </v-radio-group>
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__ms_id"
                                                    v-if="StockOrder__product_type === 2"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                </v-radio-group>
                                                <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                    <template v-if="StockOrder__manual_entry != 1">
                                        <v-layout row pt-3 v-if="addMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedPurchaseOrder') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                    :items="allArrivingPurchaseOrders"
                                                    :label="StockOrder__purchaseorder_id ? '' : $t('message.required')"
                                                    :loading="loadingRelatedPurchaseOrder"
                                                    :rules="addMode ? [...validationRules.required] : [true]"
                                                    :value="StockOrder__purchaseorder_id"
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Purchaseorder.title"
                                                    item-value="Purchaseorder.id"
                                                    return-object
                                                    solo
                                                    @change="loadArrivingPurchaseOrder($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1" @click="loader = true">
                                                            <span class="font-weight-bold">{{ item.Purchaseorder.title}}</span>
                                                            <span class="font-sm"> {{ $t('message.warehouse') + ': ' + item.Office.title }} </span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.code }} </span>
                                                            <span class="font-sm"> {{ $t('message.eta') + ': ' + formatDate(item.Contract.etadate) }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-6 v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadAnotherStockArrival') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                    :items="allActiveStockOrders"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    dense
                                                    hide-details="auto"
                                                    item-value="StockOrder.id"
                                                    item-text="StockOrder.title"
                                                    solo
                                                    @change="loadAnotherStockArrival($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold" v-if="item.StockOrder.Purchaseorder.title != null">{{ item.StockOrder.Purchaseorder.title}}</span>
                                                            <span class="font-weight-bold" v-else>{{ item.StockOrder.title}}</span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.StockOrder.Supplier.code }} </span>
                                                            <span class="font-sm"> {{ $t('message.etaWh') + ': ' + formatDate(item.StockOrder.stockindate) }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etaWh') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="stockOrderStockInDateMenu"
                                                    transition="scale-transition"
                                                    v-model="stockOrderStockInDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :label="StockOrder__stockindate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedStockOrderStockInDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="StockOrder__stockindate"
                                                        @input="stockOrderStockInDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedPurchaseOrder') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <span class="font-weight-bold">{{ StockOrder__title }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <span class="font-weight-bold">{{ StockOrder__Supplier__title }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__product_type"
                                                >
                                                    <v-radio :label="$t('message.others')" :value="1"/>
                                                    <v-radio :label="$t('message.slicedVeneer')" :value="2"/>
                                                </v-radio-group>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="StockOrder__bundle_list_version > 1.0">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__ms_id"
                                                    v-if="StockOrder__product_type === 1"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                    <v-radio :label="$t('message.imperial')" :value="2"/>
                                                </v-radio-group>
                                                <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="StockOrder__ms_id"
                                                    v-if="StockOrder__product_type === 2"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                </v-radio-group>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">
                                    <span v-if="StockOrder__product_type === 1">{{ $t('message.bundles') }}</span>
                                    <span v-if="StockOrder__product_type === 2">{{ $t('message.pallets') }}</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs no-left-padding">
                                    <Transition>
                                        <StockOrderItemList v-if="loader"/>
                                    </Transition>
                                    <v-overlay :value="loading.items" opacity="0.15">
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-progress-circular
                                                    color="primary"
                                                    indeterminate
                                                    size="40"
                                                    width="6"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-overlay>
                                    <BundleList
                                        :update-mode="updateMode"
                                        v-if="!loader && StockOrder__bundle_list_version === '1.0'"
                                    />
                                    <BundleListV2
                                        :load-options="loadOptions"
                                        :update-mode="updateMode"
                                        :validate.sync="validateStockOrderItems"
                                        @items-invalid="setItemsValidity(false)"
                                        @items-valid="setItemsValidity(true)"
                                        @reloading-options="reloadingOptions"
                                        @options-reloaded="optionsReloaded"
                                        v-if="!loader && StockOrder__bundle_list_version === '1.1'"
                                    />
                                    <BundleListV3
                                        :validate.sync="validateStockOrderItems"
                                        :update-mode="updateMode"
                                        @bundles-uploaded="bundlesUploaded"
                                        @items-invalid="setItemsValidity(false)"
                                        @items-valid="setItemsValidity(true)"
                                        @reloading-options="reloadingOptions"
                                        @options-reloaded="optionsReloaded"
                                        @reload-item-list="reloadItemList"
                                        v-if="!loader && StockOrder__bundle_list_version === '1.2'"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.otherDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierInvoiceNumber') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                :value="StockOrder__invno"
                                                autocomplete="off"
                                                class="force-text-left"
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="StockOrder__invno = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="localSalesInvoiceDateMenu"
                                                    transition="scale-transition"
                                                    v-model="stockOrderInvoiceDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="StockOrder__invdate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedStockOrderInvoiceDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            required
                                                            solo
                                                            v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="StockOrder__invdate"
                                                        @input="stockOrderInvoiceDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.containers') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="StockOrder__container"
                                                    autocomplete="off"
                                                    class="force-text-left"
                                                    dense
                                                    hide-details="auto"
                                                    rows="2"
                                                    solo
                                                    @change="StockOrder__container = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.warehouseProductGrouping') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="warehouseProductGroups"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    item-text="WarehouseProductGroup.title"
                                                    item-value="WarehouseProductGroup.id"
                                                    solo
                                                    v-model="StockOrder__speciesgroup_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_stockorder')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="stockorders" :asset_id="StockOrder__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            :loading="loading.reset"
                                            @click="resetStockOrderForm()"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode === true"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="addStockOrder()"
                                            v-if="addMode === true"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveStockOrder()"
                                            v-if="updateMode === true"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="updateMode === true"
                                            @click="cancelStockOrder(StockOrder__id)"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="false"
                                            @click.stop="viewPdf()"
                                    >
                                        <v-icon small>far fa-file-pdf</v-icon>
                                        <span class="ml-1">{{ $t('message.bundleList') }}</span>
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
    </div>
</template>

<script>
import StockOrderAlert from "Components/Appic/StockOrderAlert";
import BundleList from "Components/Appic/BundleList";
import {formatDate, isObject, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import router from "@/router";
import SimpleAlert from "Components/Appic/SimpleAlert";
import {api} from "Api";
import { v4 as uuidv4 } from 'uuid';
import {log} from "../../../helpers/helpers";
import BundleListV2 from "Components/Appic/BundleListV2.vue";
import {validationRules} from "@/store/modules/appic/constants";
import BundleListV3 from "Components/Appic/BundleListV3.vue";
const StockOrderItemList = () => import('Components/Appic/Loading/StockOrderItemList');
const DocumentHistory = () => import("Components/Appic/DocumentHistory");

export default {
    name: "AddStockOrder",
    components: {
        BundleList,
        BundleListV2,
        BundleListV3,
        DocumentHistory,
        SimpleAlert,
        StockOrderAlert,
        StockOrderItemList
    },
    props: ['stockOrderId'],
    title: '',
    data () {
        return {
            allActiveStockOrders: [],
            allArrivingPurchaseOrders: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            loading: {
                add: false,
                exRate: false,
                items: false,
                reset: false,
                save: false
            },
            loadOptions: false,
            loadingRelatedPurchaseOrder: false,
            loader: false,
            panel: [0,1],
            pageKey: 0,
            stockOrderInvoiceDateMenu: false,
            stockOrderStockInDateMenu: false,
            validateStockOrderItems: false,
            validStockOrderItems: false,
            validForm: true
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stockorder',{
            StockOrder__blno: 'StockOrder.blno',
            StockOrder__container: 'StockOrder.container',
            StockOrder__currency_id: 'StockOrder.currency_id',
            StockOrder__currencyrate: 'StockOrder.currencyrate',
            StockOrder__currencyratedate: 'StockOrder.currencyratedate',
            StockOrder__forwardingcost: 'StockOrder.forwardingcost',
            StockOrder__id: 'StockOrder.id',
            StockOrder__invdate: 'StockOrder.invdate',
            StockOrder__invno: 'StockOrder.invno',
            StockOrder__lmeasurement_id: 'StockOrder.lmeasurement_id',
            StockOrder__manual_entry: 'StockOrder.manual_entry',
            StockOrder__ms_id: 'StockOrder.ms_id',
            StockOrder__office_id: 'StockOrder.office_id',
            StockOrder__product_type: 'StockOrder.product_type', //new field
            StockOrder__purchaseorder_id: 'StockOrder.purchaseorder_id', //new field
            StockOrder__smeasurement_id: 'StockOrder.smeasurement_id',
            StockOrder__speciesgroup_id: 'StockOrder.speciesgroup_id',
            StockOrder__stock_country_id: 'StockOrder.stock_country_id',
            StockOrder__stockdescription: 'StockOrder.stockdescription',
            StockOrder__stockindate: 'StockOrder.stockindate',
            StockOrder__stockindatestatus_id: 'StockOrder.stockindatestatus_id',
            StockOrder__supplier_id: 'StockOrder.supplier_id',
            StockOrder__title: 'StockOrder.title',
            StockOrder__vmeasurement_id: 'StockOrder.vmeasurement_id',
            StockOrder__warehousecost: 'StockOrder.warehousecost',
            StockOrder__wmeasurement_id: 'StockOrder.wmeasurement_id',
            StockOrder__Contract__productorigin_id: 'StockOrder.Contract.productorigin_id',
            StockOrder__Supplier__title: 'StockOrder.Supplier.title',
            StockOrder__bundle_list_version: 'StockOrder.bundle_list_version'
        }, 'statePrefix'),
        ...mapFields('stockorder',{
            newStockOrderSaved: 'newStockOrderSaved',
            newStockOrderUnsaved: 'newStockOrderUnsaved',
            updatedStockOrderUnsaved: 'updatedStockOrderUnsaved',
            updatedStockOrderSaved: 'updatedStockOrderSaved',
            warehouseProductGroups: 'warehouseProductGroups'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters([
            'currencies',
            'uofmDimensions',
            'uofmQty',
            'offices',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_stockorder')
        },
        computedStockOrderInvoiceDateFormatted () {
            return formatDate(this.StockOrder__invdate)
        },
        computedStockOrderStockInDateFormatted () {
            return formatDate(this.StockOrder__stockindate)
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_stockorder')
        },
        whOffices: function () {
            return this.offices.filter(office => office.Office.has_warehouse == true)
        }
    },
    methods: {
        ...mapActions( 'stockorder', {
            cancelStockOrderById: 'cancelStockOrderById',
            createStockOrder: 'createStockOrder',
            getStockOrderToUpdateById: 'getStockOrderToUpdateById',
            getWarehouseProductGroups: 'getWarehouseProductGroups',
            resetCurrentStockOrder: 'resetCurrentStockOrder',
            updateStockOrder: 'updateStockOrder'
        }),
        ...mapActions( 'stock', {
            getBundlesByStockOrder: 'getBundlesByStockOrder',
            setBundlesByStockPo: 'setBundlesByStockPo'
        }),
        ...mapActions('supplier', {
            getAllSuppliers: 'getAllSuppliers'
        }),
        addStockOrder () {
            this.validateStockOrderItems = true
            let _this = this
            setTimeout(function() {
                if(_this.validStockOrderItems) {
                    if (_this.$refs.stockOrderForm.validate()) {
                        _this.loading.add = true
                        _this.createStockOrder()
                                .then(response => {
                                    if (response.status == 'success') {
                                        _this.$toast.success(_this.$t('message.successes.stockArrivalAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                        )
                                        _this.loading.add = false
                                        _this.newStockOrderUnsaved = false
                                        _this.newStockOrderSaved = true
                                        _this.resetCurrentStockOrder()
                                                .then(() => {
                                                    router.push({
                                                        name: 'update_stockorder',
                                                        params: {stockOrderId: response.stockorder_id}
                                                    }).catch(err => {
                                                        console.log(err)
                                                    })
                                                    _this.$store.commit('stock/resetCurrentState')
                                                })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.stockArrivalNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                        )
                                        _this.loading.add = false
                                    }
                                })
                                .catch(() => {
                                    _this.$toast.error(_this.$t('message.errors.stockArrivalNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                    )
                                    _this.loading.add = false
                                })
                    } else {
                        _this.$toast.error(_this.$t('message.errors.stockArrivalNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                        _this.loading.add = false
                        scrollToFirstFormValidationError()
                    }
                } else {
                    _this.$toast.error(_this.$t('message.errors.stockArrivalNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                    )
                    _this.loading.save = false
                    scrollToFirstFormValidationError()
                    // setTimeout(_this.saveNcr,300)
                }
            },1000)
        },
        bundlesUploaded() {
            if(this.updateMode && this.StockOrder__bundle_list_version === '1.2'){
                this.saveStockOrder()
            }
        },
        async cancelStockOrder (stockOrderId) {
            if(await this.$root.$confirm(this.$t('message.cancelStockArrival'), this.$t('message.confirmations.continueStockArrivalCancelAction'), {color: 'orange'})) {
                this.cancelStockOrderById(stockOrderId)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.stockArrivalDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'stockorders_listing'}).catch(err => {
                            })
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.stockArrivalNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.localSalesInvoiceNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.cancel = false
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getActiveStockArrivals () {
            api
                .get('/stockorders/active')
                .then( (response) => {
                    this.allActiveStockOrders = response.data.data
                })
        },
        getAllArrivingPurchaseOrders () {
            api
                .get('/stockorders/arriving')
                .then( (response) => {
                    this.allArrivingPurchaseOrders = response.data.data
                })
        },
        getUpdatedExRate() {
            let warehouseCurrency = 'MYR';

            if(this.StockOrder__office_id === 21) warehouseCurrency = 'IDR';

            const currencies = {
                source: 'USD',
                target: warehouseCurrency
            };

            this.loading.exRate = true;

            api
                .put('/reports/stocks/user-exchange-rate', {
                    currencies: currencies
                })
                .then((response) => {
                    if(response.data.status === 'success') {
                        this.StockOrder__currencyrate = response.data.rate
                    } else {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.exRate = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.exRate = false;
                })
        },
        loadAnotherStockArrival (val) {
            router.push({name: 'update_stockorder', params: { stockOrderId : val}}).catch(err => {})
        },
        loadArrivingPurchaseOrder (purchaseorder) {
            if(!this.loader) this.loader = true
            this.StockOrder__purchaseorder_id = purchaseorder.Purchaseorder.id
            this.StockOrder__blno = purchaseorder.Contract.blno
            this.StockOrder__container = purchaseorder.Purchaseorder.containers
            this.StockOrder__office_id = purchaseorder.Office.id

            // this.StockOrder__purchaseorder_id = purchaseorder.Purchaseorder.id
            this.StockOrder__stock_country_id = purchaseorder.Contract.destinatione_id
            this.StockOrder__supplier_id = purchaseorder.Supplier.id
            this.StockOrder__title = purchaseorder.Purchaseorder.title
            this.StockOrder__Contract__productorigin_id = purchaseorder.Contract.productorigin_id
            this.StockOrder__Supplier__title = purchaseorder.Supplier.title

            let office = this.offices.find( o => o.Office.id === this.StockOrder__office_id)

            this.StockOrder__currency_id = office?.Office.currency_id
            this.StockOrder__stock_country_id = office?.Office.country_id

            //set ETA WH to auto calculate from PO ETA + 7 days
            const d = new Date(purchaseorder.Contract.etadate)
            const eta = d.setDate(d.getDate() + 7)
            this.StockOrder__stockindate = new Date(eta).toISOString().substring(0,10)

            this.StockOrder__manual_entry = null;

            //set measurement system
            if(purchaseorder.Contract.ms_id != null){
                this.StockOrder__ms_id = purchaseorder.Contract.ms_id
            } else {
                this.StockOrder__ms_id = 1
            }

            //load PO items new 2023-10-02
            api
                .get('/stockorders/po/' + this.StockOrder__purchaseorder_id + '/items')
                .then((response) => {
                    if(response.data.status === 'success'){
                        this.setBundlesByStockPo(response.data.data.bundles)
                        this.StockOrder__currencyrate = response.data.data.exrate
                        const _this = this
                        this.$nextTick(function(){
                            _this.loadOptions = true
                        })
                    } else {
                        this.$toast.error(this.$t('message.errors.stockPoItemsNotLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                    }
                    if(this.loader) this.loader = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.stockPoItemsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                    )
                    if(this.loader) this.loader = false
                })
        },
        loadStockOrderById (val) {
            this.loader = true
            this.getStockOrderToUpdateById( val )
                .then(() => {
                    this.$title =  this.$t('message.titles.stockEntry') + ": " + this.StockOrder__title
                    let payload = {
                        prefix: this.statePrefix,
                        stockOrderId: val
                    }
                    this.getBundlesByStockOrder(payload)
                        .then( () => {
                            this.loader = false
                            this.updatedStockOrderUnsaved = false
                        })
                        .catch(() => {
                            this.loader = false
                            this.updatedStockOrderUnsaved = false
                        })
                })
                .catch( () => {
                    this.loader = false
                    this.updatedStockOrderUnsaved = false
                    router
                        .push({name: 'not_found_main', params: {resource: 'stock entry'}})
                        .catch(() => {
                            console.log('error')
                        })
                })
        },
        officeChanged(officeId) {
            const office = this.offices.find(office => office.Office.id === officeId)
            if(office) {
                this.StockOrder__stock_country_id = office.Office.country_id
                this.StockOrder__currency_id = office.Office.currency_id
            }
        },
        optionsReloaded() {
            if(this.loader) this.loader = false
            if(this.loadOptions) this.loadOptions = false
        },
        reloadingOptions() {
            this.loader = true
        },
        reloadItemList(value) {
            if(value.reloadList) {
                this.loading.items = true
                this.updateStockOrder()
                    .then(() => {
                        this.loading.save = true
                        const payload = {
                            stockOrderId: this.stockOrderId,
                            prefix: this.statePrefix
                        }
                        this.getBundlesByStockOrder(payload)
                            .then(() => {
                                this.loading.items = false
                                this.loading.save = false
                                this.updatedStockOrderUnsaved = false
                            })
                            .catch(()=>{
                                this.loading.items = false
                                this.loading.save = false
                                this.updatedStockOrderUnsaved = false
                            })
                    })
                    .catch(()=>{
                        this.loading.items = false
                        this.loading.save = false
                        this.updatedStockOrderUnsaved = false
                    })
            } else {
                if(this.updateMode) {
                    this.loading.items = true
                    this.updateStockOrder()
                        .then(() => {
                            this.loading.items = false
                        })
                        .catch(() => {
                            this.loading.items = false
                        })
                }
            }
        },
        async resetStockOrderForm () {
            if (await this.$root.$confirm(this.$t('message.resetStockArrivalForm'), this.$t('message.confirmations.continueStockArrivalFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentStockOrder()
                    .then(() => {
                        this.newStockOrderSaved = false
                        this.newStockOrderUnsaved = false
                        this.loading.reset = false
                        this.$toast.success(this.$t('message.stockArrivalFormResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveStockOrder () {
            this.validateStockOrderItems = true
            let _this = this
            setTimeout(function() {
                if(_this.validStockOrderItems) {
                    if (_this.$refs.stockOrderForm.validate()) {
                        _this.loading.save = true
                        _this.updateStockOrder()
                            .then((response) => {
                                if (response.status === 'success') {
                                    _this.$toast.success( _this.$t('message.successes.stockArrivalUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    const payload = {
                                        prefix: _this.statePrefix,
                                        stockOrderId: _this.stockOrderId
                                    }
                                    _this.getBundlesByStockOrder(payload);
                                    _this.loading.save = false
                                    _this.updatedLocalSalesInvoiceUnsaved = false
                                } else {
                                    if(isObject(response.message)){
                                        //extract validation errors
                                        const errors = Object.values(response.message)
                                        errors.forEach(errorObj => {
                                            if (Array.isArray(errorObj)) {
                                                errorObj.forEach(error => {
                                                    _this.$toast.error(error,
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'error_outline'
                                                        }
                                                    )
                                                })
                                            } else {
                                                // console.log('here1')
                                                _this.$toast.error(_this.$t('message.errors.stockArrivalNotUpdated'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            }
                                        })
                                    } else {
                                        // console.log('here2')
                                        _this.$toast.error(_this.$t('message.errors.stockArrivalNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                    _this.loading.save = false
                                }
                            })
                            .catch((err) => {
                                // console.log(err)
                                // console.log('here3')
                                _this.$toast.error(_this.$t('message.errors.stockArrivalNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                )
                                _this.loading.save = false
                            })
                    } else {
                        // console.log('here4')
                        _this.$toast.error(_this.$t('message.errors.stockArrivalNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                        scrollToFirstFormValidationError()
                    }
                } else {
                    // console.log('here5')
                    _this.$toast.error(_this.$t('message.errors.stockArrivalNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                    )
                    scrollToFirstFormValidationError()
                }
            },1000)
        },
        setItemsValidity(val){
            this.validStockOrderItems = val
            this.validateStockOrderItems = false
        },
        viewPdf () {
            let document = encodeURIComponent(this.StockOrder__title)
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/stockorders/print/'
                + this.stockOrderId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {},
    created () {
        if(this.warehouseProductGroups.length === 0) this.getWarehouseProductGroups()
        if(this.allSuppliers.length === 0) this.getAllSuppliers()

        if(this.$route.matched.some(({name}) => name === 'update_stockorder')) {
            this.loadStockOrderById(this.stockOrderId)
            this.getActiveStockArrivals()
        } else if(this.$route.matched.some(({name}) => name === 'add_stockorder')){
            this.StockOrder__bundle_list_version = '1.2'
            this.getAllArrivingPurchaseOrders()

        }
    }
}
</script>

<style>
    .force-text-left input{
        text-align: left !important;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>